import {
  BookOpenCheck,
  ChevronRight,
  Radar,
  Rss,
  Shapes,
  Trophy,
} from 'lucide-react'

export default function Exception404({
  suggestions,
}: {
  suggestions: Array<{
    title: string
    description: string
    icon: string
    href: string
  }>
}) {
  const icons = {
    BookOpenCheck: BookOpenCheck,
    Trophy: Trophy,
    Shapes: Shapes,
    Radar: Radar,
    Rss: Rss,
  }

  return (
    <section className="my-0 mx-auto text-sm not-prose">
      <div className="grid min-h-full place-items-center">
        <div className="text-center">
          <p className="text-2xl -tracking-widest font-semibold text-amber-500">
            404
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-zinc-800 sm:text-5xl">
            Página não encontrada
          </h1>
          <p className="mt-6 text-sm leading-6 lg:text-base lg:leading-7 text-zinc-600">
            Verifique se o endereço da página foi digitado corretamente.
          </p>
          <div className="mx-auto mt-4 lg:mt-20 flow-root max-w-[32rem]">
            <h2 className="absolute w-[1px] h-[1px] p-0 -m-[1px] overflow-hidden whitespace-nowrap border-0">
              Páginas populares
            </h2>
            {suggestions.map(({ title, description, icon, href }) => {
              const I = icons[icon]
              return (
                <ul className="border-b border-b-zinc-200" key={title}>
                  <li>
                    <a
                      className="py-6 relative flex gap-6"
                      aria-label={title}
                      href={href}
                    >
                      <div className="flex items-center justify-center rounded-md flex-none w-10 h-10 border border-zinc-300">
                        {I && <I className="h-6 w-6 text-amber-500" />}
                      </div>
                      <div className="flex-auto text-left">
                        <h3 className="text-zinc-800 leading-6 font-semibold text-sm">
                          {title}
                        </h3>
                        <p className="text-zinc-700 leading-6 text-sm">
                          {description}
                        </p>
                      </div>
                      <div className="self-center flex-none">
                        <ChevronRight className="text-zinc-400 w-5 h-5" />
                      </div>
                    </a>
                  </li>
                </ul>
              )
            })}
          </div>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-amber-500 underline"
            >
              <span aria-hidden="true">&larr;</span> Voltar para o início
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
